@import "react-perfect-scrollbar/dist/css/styles.css";
@import 'react-big-calendar/lib/css/react-big-calendar.css';

html {
  font-size: 8px;
}

.sb-show-main {
  overflow: hidden;
}
.sb-show-main #root {
  overflow-y: auto;
}

#root {
  max-height: 100vh;
  overflow: hidden
}

.rbc-calendar {
  font-family: inter;
}

.rbc-date-cell {
  text-align: left;
  padding: 16px;
}

.rbc-date-cell>button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #7C85A2;
}

.rbc-header {
  text-align: left;
  padding: 16px;
  background: #F3F4F7;
}

.rbc-header>span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #7C85A2;

}

.rbc-event-content {
  font-size: 10px;
}


.rbc-overlay {
  padding: 1.5rem 2rem;
  font-family: "Inter";
  box-shadow: 0 1rem 1.5rem 0 rgba(9, 30, 66, 0.08);
  border: 0.125rem solid rgba(225, 227, 234, 1);
  border-radius: 1rem;
  max-width: 33.5rem;
}

.rbc-overlay-header {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
  padding: 0;
  border: none;
  margin: 0 0 1.5rem;
}

.rbc-event-continues-after {
  margin-top: 1rem;
  outline: none;
  padding: 0;
}

.rbc-event-continues-after:focus {
  outline: none;
}

.rbc-event-continues-after .rbc-event-content {
  font-size: 1.50rem;
  line-height: 2.25rem;
  padding: 0.375rem 1.25rem;
  font-weight: 400;
  white-space: nowrap;
  outline: none;
  letter-spacing: 0.019rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbc-event-continues-after .rbc-event-content svg {
  background-color: rgba(33, 82, 99, 1);
  color: white;
  padding: 0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  margin-right: 0.75rem;
  vertical-align: bottom;
}

.sb-show-main {
  overflow: hidden;
}

.sb-show-main #root {
  overflow-y: auto;
}

.nestedTable {
  padding: 0 !important;
}

@media print {
  .no-print {
    display: none !important; 
  }

  .mySalaryDetails div table tbody tr td {
    padding: 8px;
  }

  .mySalaryDetails {
    padding: 0 10px;
  }

  .report-expenses-details {
    padding: 0 10px;
  }

  .compareTds {
    padding: 0 15px;
  }

  .form12bb{
    padding: 0 20px;
  }
}