:root {
	--colorBackgroundPage: #ffffff;
	--colorBackgroundPageFaded: #f5f9fe;
	--colorBackgroundBase: #ffffff;
	--colorBackgroundElevated: #142746;
	--colorBackgroundElevatedHighlighted: #263b5e;
	--colorBackgroundElevated2: #3a5075;
	--colorBackgroundElevated2Highlighted: #48618a;
	--colorBackgroundNeutral: #f5f9fe;
	--colorBackgroundNeutralFaded: #cedcf3;
	--colorBackgroundNeutralHighlighted: #e7f0fd;
	--colorBackgroundDisabled: #eff3f8;
	--colorBackgroundPrimary: #2066e4;
	--colorBackgroundPrimaryFaded: #eaf1fd;
	--colorBackgroundPrimaryHighlighted: #1855c4;
	--colorBackgroundPositive: #2066e4;
	--colorBackgroundPositiveHighlighted: #eaf1fd;
	--colorBackgroundCritical: #e13755;
	--colorBackgroundCriticalHighlighted: #ffedf0;
	--colorBackgroundToothHighlighted: #e6eeff;
	--colorBackgroundToothActive: #96baff;
	--colorOnBackgroundNeutral: #03122b;
	--colorOnBackgroundPrimary: #ffffff;
	--colorOnBackgroundPositive: #ffffff;
	--colorOnBackgroundCritical: #ffffff;
	--colorOnBackgroundElevated: #ffffff;
	--colorOnBackgroundElevatedFaded: #3c455d;
	--colorOnBackgroundNeutralHighlighted: #03122b;
	--colorOnBackgroundPrimaryHighlighted: #ffffff;
	--colorOnBackgroundPositiveHighlighted: #ffffff;
	--colorOnBackgroundCriticalHighlighted: #ffffff;
	--colorOnBackgroundElevatedHighlighted: #ffffff;
	--colorForegroundNeutral: #03122b;
	--colorForegroundNeutralFaded: #263b5e;
	--colorForegroundNeutralHighlighted: #41587e;
	--colorForegroundNeutralLight: #5976a6;
	--colorForegroundNeutralLightFaded: #6e8bb9;
	--colorForegroundNeutralLightHighlighted: #6883af;
	--colorForegroundDisabled: #adb5c5;
	--colorForegroundPrimary: #2066e4;
	--colorForegroundPositive: #2066e4;
	--colorForegroundCritical: #e13755;
	--colorForegroundWhite: #ffffff;
	--colorBorderNeutral: #e7f0fd;
	--colorBorderNeutralFaded: #f5f9fe;
	--colorBorderNeutralHighlighted: #cedcf3;
	--colorBorderTooth: #a7bbdf;
	--colorBorderToothHighlighted: #5a93ff;
	--colorBorderToothActive: #1365ff;
	--colorBorderPrimary: #2066e4;
	--colorBorderPositive: #2066e4;
	--colorBorderCritical: #e13755;
	--colorBorderWhite: #ffffff;
	--colorBlackNWhiteBlack: #03122b;
	--colorBlackNWhiteWhite: #ffffff;
	--colorTabToday: #d629a5;
	--colorTabPlanOne: #2066e4;
	--colorTabPlanTwo: #31c931;
	--colorTabPlanThree: #18b8c2;
	--colorTabPlanFour: #e9873a;
	--colorTabPlanFive: #7823e3;
	--colorTabPlanSix: #e35036;
	--colorFindingFillingStroke: #064aca;
	--colorFindingFillingFill: #2a6dec;
	--colorFindingFillingFaded: #c9dbfa;
	--colorFindingArtificialCrownStroke: #5376ae;
	--colorFindingArtificialCrownFill: #7696cf;
	--colorFindingMetallicFill: #6588c7;
	--colorFindingMetallicStroke: #3b63a4;
	--colorFindingInflamationFill: #ed1a3f;
	--colorFindingInflamationStroke: #b90222;
	--colorFindingInflamationFaded: #ffc6d0;
	--colorFindingDirtFill: #f9a84a;
	--colorFindingDirtStroke: #d97d12;
	--colorFindingDirtFaded: #ffcd94;
	--colorFindingRemovableFill: #41cceb;
	--colorFindingRemovableStroke: #1cb3d4;
	--colorFindingRemovableFaded: #97edff;
};