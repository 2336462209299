:root {
	--fontFamily: Montserrat, sans-serif;
	--fontWeightMedium: 500;
	--fontWeightSemibold: 600;
	--fontWeightBold: 700;
	--fontSizeH1: 32px;
	--fontSizeH2: 25px;
	--fontSizeH3: 22px;
	--fontSizeH4: 16px;
	--fontSizeBody: 14px;
	--fontSizeBodySmall: 12px;
	--fontH1Bold: normal normal 700 32px/40px Montserrat, sans-serif;
	--fontH1Semibold: normal normal 600 32px/40px Montserrat, sans-serif;
	--fontH1Medium: normal normal 500 32px/40px Montserrat, sans-serif;
	--fontH2Bold: normal normal 700 25px/32px Montserrat, sans-serif;
	--fontH2Semibold: normal normal 600 25px/32px Montserrat, sans-serif;
	--fontH2Medium: normal normal 500 25px/32px Montserrat, sans-serif;
	--fontH3Bold: normal normal 700 22px/28px Montserrat, sans-serif;
	--fontH3Semibold: normal normal 600 22px/28px Montserrat, sans-serif;
	--fontH3Medium: normal normal 500 22px/28px Montserrat, sans-serif;
	--fontH4Bold: normal normal 700 16px/24px Montserrat, sans-serif;
	--fontH4Semibold: normal normal 600 16px/24px Montserrat, sans-serif;
	--fontH4Medium: normal normal 500 16px/24px Montserrat, sans-serif;
	--fontBodyBold: normal normal 700 14px/20px Montserrat, sans-serif;
	--fontBodySemibold: normal normal 600 14px/20px Montserrat, sans-serif;
	--fontBodyMedium: normal normal 500 14px/20px Montserrat, sans-serif;
	--fontBodySmallBold: normal normal 700 12px/16px Montserrat, sans-serif;
	--fontBodySmallSemibold: normal normal 600 12px/16px Montserrat, sans-serif;
	--fontBodySmallMedium: normal normal 500 12px/16px Montserrat, sans-serif;
	--fontLineHeightH1: 40px;
	--fontLineHeightH2: 32px;
	--fontLineHeightH3: 28px;
	--fontLineHeightH4: 24px;
	--fontLineHeightBody: 20px;
	--fontLineHeightBodySmall: 16px;
};