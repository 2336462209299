[data-slate-placeholder] {
  color: var(--colorForegroundNeutralLight);
  font: var(--fontBodyMedium);
}

.drag-button {
  overflow: hidden;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  border-style: none;
  cursor: pointer;
  outline: 0;

  min-width: 2.25rem;
  min-height: 2.25rem;
}

.bit-text-editor-container td  {
  border: 0.125rem solid;
}

hr {
  background: #ececec !important;
}

.slate-ToolbarButton>svg {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.slate-ToolbarButton {
  margin: 0 1rem;
}